.my-node-enter {
    opacity: 0;
    transform: translateX(100%);
}
.my-node-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition:all 1000ms ease;
}
.my-node-exit {
    opacity: 1;
}
.my-node-exit-active {
    opacity: 0;
    transition: opacity 1000ms;
}